import axios from "axios";

function base() {
  if (process.env.REACT_APP_API_BASE) {
    return process.env.REACT_APP_API_BASE;
  } else if (process.env.NODE_ENV === "development") {
    return "http://localhost:7074/api/";
  } else {
    return "https://360-api-dev.socif.co/api/";
  }
}

export const api = axios.create({
  baseURL: base(),
  timeout: 60 * 1000,
  validateStatus: (status) => status <= 500,
});
