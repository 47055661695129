import { createTheme } from "@mui/material/styles";
import { types } from "mobx-state-tree";
import { createContext, useContext } from "react";

const RootStore = types
  .model({
    loadedShop: types.boolean,
    startedTrip: types.boolean,
    logoUrl: types.maybe(types.string),
  })
  .volatile((self) => ({
    theme: createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
      },
    }),
  }))
  .actions((self) => ({
    setPrimaryColor: (color: string) => {
      self.theme = createTheme({
        palette: {
          primary: {
            main: color,
          },
        },
      });
    },
    setLogoUrl: (url: string) => {
      self.logoUrl = url;
    },
    setLoadedShop: (state: boolean) => {
      self.loadedShop = state;
    },
    setStartedTrip: (state: boolean) => {
      self.startedTrip = state;
    },
  }));

const rootStore = RootStore.create({
  loadedShop: false,
  startedTrip: false,
});

/**
 * Root Store Context
 */
export const RootStoreContext = createContext(rootStore);
type TypeRootStore = typeof rootStore;
type MapStateToProps<T> = (arg0: TypeRootStore) => T;

export function useRootStore<K>(mapStateToProps: MapStateToProps<K>): K;
export function useRootStore(): TypeRootStore;
export function useRootStore<K = undefined>(
  mapStateToProps?: undefined | MapStateToProps<K>
): K | TypeRootStore {
  const store: TypeRootStore = useContext(RootStoreContext);

  if (typeof mapStateToProps !== "undefined") {
    return mapStateToProps(store);
  } else {
    return store;
  }
}
