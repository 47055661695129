import { ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { MapProvider } from "react-map-gl";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./containers/Home";
import Order from "./containers/Order";
import { useRootStore } from "./utils/stores/rootStore";

function App() {
  const { theme } = useRootStore();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MapProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/order/:orderId" element={<Order />} />
          </Routes>
        </MapProvider>
      </ThemeProvider>
    </div>
  );
}

export default observer(App);
