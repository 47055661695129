import { Box } from "@mui/material";

const Credit = () => {
  return (
    <Box sx={{ typography: "caption", marginY: 3, color: "#828282" }}>
      追蹤服務由
      <a target={"_blank"} rel="noreferrer" href="https://socif.co">
        收貨鳥
      </a>
      提供
    </Box>
  );
};

export default Credit;
