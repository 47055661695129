import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Layer, Marker, Source, useMap } from "react-map-gl";
import { useParams } from "react-router-dom";
import { useETA } from "../../api/useETA";
import { useOrder } from "../../api/useOrder";
import Truck from "../../assets/cargo-truck.svg";
import ContactInfo from "../../components/ContactInfo";
import Credit from "../../components/Credit";
import ETACard from "../../components/ETACard";
import Header from "../../components/Header";
import MapContainer from "../../components/MapContainer";
import OrderDetails from "../../components/OrderDetails";
import RatingHelper from "../../components/RatingHelper";
import "./styles.scss";

const Order = () => {
  const { map1: map } = useMap();
  const params = useParams();
  // --- states
  const { shopEta, path, deliveryStatus } = useETA(params.orderId);
  const { orderSummary } = useOrder({
    onSuccess: (data) => {
      if (data?.order?.shape !== undefined) {
        if (!shopEta?.vehicleLocation) {
          map?.flyTo({
            // @ts-ignore
            center: data.order.shape.coordinates!,
            zoom: 13,
          });
        }
      }
    },
    orderId: params.orderId
  });

  // --- return
  return (
    <Box sx={{ paddingX: 3, paddingY: 1 }}>
      <Header />
      <Box sx={{ width: "100%", marginBottom: 2 }}>
        <ETACard orderId={params.orderId} />
      </Box>
      <MapContainer>
        {shopEta?.vehicleLocation?.coordinates !== undefined && (
          <Marker
            latitude={shopEta.vehicleLocation?.coordinates[1]}
            longitude={shopEta.vehicleLocation?.coordinates[0]}
            anchor="center"
          >
            <Box
              sx={{
                padding: 1,
                width: 32,
                height: 32,
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 0px 10px 1px #E9E9E9",
              }}
            >
              <img alt={"Vehicle Location"} src={Truck} width={24} />
            </Box>
          </Marker>
        )}
        {orderSummary?.order?.shape !== undefined && (
          <Marker
            latitude={orderSummary?.order?.shape?.coordinates?.[1]}
            longitude={orderSummary?.order?.shape?.coordinates?.[0]}
            anchor="bottom"
          >
            <Box
              sx={{
                padding: 1,
                width: 8,
                height: 8,
                backgroundColor:
                  orderSummary?.shop?.themeColour || "rgba(3, 170, 238, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 0px 10px 1px #E9E9E9",
              }}
            >
              <Box sx={{ width: 6, height: 6, backgroundColor: "white" }}></Box>
            </Box>
          </Marker>
        )}

        {/in_progress|arriving|suspended/.test(deliveryStatus) &&
          path !== undefined && (
            <Source
              id="polylineLayer"
              type="geojson"
              data={{
                type: "Feature",
                geometry: path,
                properties: {},
              }}
            >
              <Layer
                id="lineLayer"
                type="line"
                source="my-data"
                layout={{
                  "line-join": "round",
                  "line-cap": "round",
                }}
                paint={{
                  "line-color":
                    orderSummary?.shop?.themeColour || "rgba(3, 170, 238, 0.8)",
                  "line-width": 5,
                }}
              />
            </Source>
          )}
      </MapContainer>
      <ContactInfo orderId={params.orderId} />
      <OrderDetails orderId={params.orderId} />
      <RatingHelper orderId={params.orderId} />
      <Credit />
    </Box>
  );
};

export default observer(Order);
