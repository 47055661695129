import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import PickupBird from "../../assets/PickupBird.png";
import { useRootStore } from "../../utils/stores/rootStore";
import "./styles.scss";

const Header = () => {
  const { logoUrl } = useRootStore();

  return (
    <div className="header">
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginRight: 1 }}>
            <img alt="PickupBird" src={PickupBird} width={24} />
          </Box>
          <Box sx={{ typography: "h6", fontWeight: "bold", color: "black" }}>收貨鳥</Box>
        </Box>
      </Link>
      <Box sx={{ width: 128 }}>
        {!!logoUrl && (
          <img
            alt={"Mall Logo"}
            src={logoUrl}
            width={"100%"}
            className="logo"
          />
        )}
      </Box>
    </div>
  );
};

export default observer(Header);
