import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Modal,
  Paper,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMutation } from "react-query";
import { api } from "../../api/axios";
import { useRootStore } from "../../utils/stores/rootStore";
import "./styles.scss";

interface RatingHelperProps {
  orderId?: string;
}

const RatingHelper = ({ orderId }: RatingHelperProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { startedTrip, theme } = useRootStore();

  // --- states
  const [isOpened, setIsOpened] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [comment, setComment] = useState("");

  const { mutate: onSubmit, isLoading } = useMutation(
    async () => {
      if (!orderId) {
        throw new Error("no orderId");
      }

      await api.post(`shopify/rating/${orderId}`, {
        comment: comment,
        rating: ratingValue,
      });
    },
    {
      mutationKey: ["rating", orderId],
      onSuccess: () => {
        setIsOpened(false);
        setRatingValue(0);
        setComment("");
        enqueueSnackbar("感謝您的回饋！", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("發生錯誤，請稍候再試", { variant: "error" });
      },
    }
  );

  const onDismiss = () => {
    if (!isLoading) {
      setIsOpened(false);
      setRatingValue(0);
      setComment("");
    }
  };

  return (
    <Box className="rating_container">
      <Button
        fullWidth
        disabled={!startedTrip}
        variant="contained"
        onClick={() => setIsOpened(true)}
      >
        評價收貨體驗
      </Button>
      <Modal open={isOpened} onBackdropClick={onDismiss}>
        <Paper className="modal_content">
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={2}
            padding={2}
            sx={{
              [theme.breakpoints.down("sm")]: {
                width: "85vw",
              },
            }}
          >
            <Typography>你對是次收貨體驗的評價</Typography>
            <Rating
              size={"large"}
              value={ratingValue}
              onChange={(_, v) => setRatingValue(v || 0)}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder={"其他意見..."}
              value={comment}
              onChange={(v) => setComment(v.target.value)}
            />
            <LoadingButton
              disabled={!orderId || !startedTrip}
              loading={isLoading}
              variant="contained"
              onClick={() => onSubmit()}
              size={"large"}
              fullWidth
            >
              提交
            </LoadingButton>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export default observer(RatingHelper);
