import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useRootStore } from "../utils/stores/rootStore";
import { api } from "./axios";

type Options = {
  onSuccess?: (data?: OrderSummary) => void;
  orderId?: string;
};

export const useOrder = ({ onSuccess, orderId }: Options = {}) => {
  const { setPrimaryColor, setLogoUrl, setLoadedShop } = useRootStore();

  const { data, isFetched } = useQuery(
    ["order", orderId],
    async () => {
      const response = await api.get<StandardResponse<OrderSummary>>(
        `shopify/order/${orderId}`
      );

      if (response.status === 200 && "data" in response.data) {
        return response.data.data;
      }
    },
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.shop) {
          setLoadedShop(true);
        }
        if (data?.shop?.themeColour) {
          setPrimaryColor(data.shop.themeColour);
        } else {
          setPrimaryColor("rgba(3, 170, 238, 0.8)");
        }
        if (data?.shop?.logoUrl) {
          setLogoUrl(data.shop.logoUrl);
        }
        onSuccess?.(data);
      },
    }
  );

  return { orderSummary: data, isFetched };
};
